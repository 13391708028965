<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('home.fund')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <div v-if="list.length > 0">
        <van-row class="chat" v-for="item in list" :key="item.id">
          <van-row>
            <van-col span="12" class="name">
              {{ item.username }}
              <span class="dot" v-if="+item.count">
                {{ item.count }}
              </span>
            </van-col>
            <van-col span="12" class="time">
              {{ item.lastmsg.createtime | dateformat }}
            </van-col>
          </van-row>
          <div class="info">
            <span class="textTv" v-if="item.lastmsg.type === 'text'">
              {{ item.lastmsg.content }}
            </span>
            <van-icon name="photo-o" v-else style="font-size:1rem" />
          </div>
          <div class="van-hairline--bottom"></div>
        </van-row>
      </div>
      <van-empty :description="$t('zan-wu-xiao-xi')" v-else />
      <audio controls ref="notify" style="display:none">
        <source src="msg.mp3" />
      </audio>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      websocket: null,
      userid: "",
    };
  },
  created() {
    this.isread();

    this.getinfo();
  },
  methods: {
    async isread() {
      const { data } = await this.$http.get("/home/user/isread");
    },
    async getinfo() {
      const { data } = await this.$http.get("/home/user/chatlist");
      if (data) {
        if (data.code === 200) {
          this.list = data.data.list;
          this.userid = data.data.userid;
          if (this.websocket == null) {
            this.initwebsocket();
          }
        }
      }
    },
    // 去聊天页
    tochat(orderid) {
      this.$router.push("/trade/shopping/trading/contact/" + orderid);
    },
    // 初始化连接
    initwebsocket() {
      this.websocket = new WebSocket(this.wsurl + "/wss:8880");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onerror = this.websocketonerror;
    },
    // 连接错误
    websocketonerror() {
      this.initwebsocket();
    },
    // 接收到数据
    websocketonmessage(e) {
      const message = JSON.parse(e.data);
      switch (message.type) {
        case "init":
          this.binduid();
          break;
        case "text":
          this.getinfo();
          this.$refs.notify.play();
          break;
        case "image":
          this.getinfo();
          this.$refs.notify.play();
          break;
      }
    },
    // 绑定uid
    binduid() {
      const msg = { type: "bind", userid: this.userid };
      this.websocketsend(JSON.stringify(msg));
    },
    // websorcket发送信息
    websocketsend(data) {
      this.websocket.send(data);
    },
  },
};
</script>

<style lang="less" scoped>
.maincontent {
  padding: 60px 0;
  display: block;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  .chat {
    padding: 12px 1rem;
    .name {
      color: #171717;
      text-align: left;
      font-size: 1rem;
      position: relative;
      .dot {
        position: absolute;
        background: #f00;
        width: 14px;
        height: 14px;
        display: inline-block;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 14px;
        font-size: 12px;
        top: 0;
      }
    }
    .time {
      font-size: 0.85rem;
      text-align: right;
      color: #999;
    }
    .info {
      text-align: left;
      font-size: 0.85rem;
      color: #999;
      margin: 8px 0 12px 0;
    }
  }
}
.textTv {
  width: 100%;
  white-space: normal;
  word-break: break-all;
  word-warp: break-word;
}
</style>
